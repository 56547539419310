import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {State} from "./State";
import {TicketsWaitingLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsWaitingLoader";
import {TicketsAcceptedLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsAcceptedLoader";
import {TicketsDeclinedLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsDeclinedLoader";
import {TicketsToValidateLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/TicketsToValidateLoader";
import {LocationsLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/LocationsLoader";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import {
    CTA_TICKETS_STATUS_ACCEPTED, CTA_TICKETS_STATUS_DECLINED,
    CTA_TICKETS_STATUS_QUEUE, CTA_TICKETS_STATUS_WAITING_LIST,
    LABEL_LOCATION_TICKETS_TITLE,
    LABEL_NO_TICKETS_IN_QUEUE
} from "../../Libraries/Constants/Labels";
import {
    backgroundColor,
    border,
    classes,
    flex, italic,
    margin,
    padding,
    round, textAlign,
    textSize,
    width
} from "../../Libraries/Theme/Theme";
import TicketWaitingCard from "../../Libraries/Components/Domain/Ticket/TicketWaitingCard/TicketWaitingCard";
import TicketInQueueCard from "../../Libraries/Components/Domain/Ticket/TicketInQueueCard/TicketInQueueCard";
import TicketAcceptedCard from "../../Libraries/Components/Domain/Ticket/TicketAcceptedCard/TicketAcceptedCard";
import TicketDeclinedCard from "../../Libraries/Components/Domain/Ticket/TicketDeclinedCard/TicketDeclinedCard";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendLoginPageView, sendTicketsPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function Tickets() {
    const {id} = useParams();
    const {setLocationId, section, changeSection} = State();
    const {getWaitingTicketsByLocationId} = TicketsWaitingLoader();
    const {getTicketsAcceptedByLocationId} = TicketsAcceptedLoader();
    const {getTicketsDeclinedByLocationId} = TicketsDeclinedLoader();
    const {getToValidateTicketsByLocationId} = TicketsToValidateLoader();
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendTicketsPageView(getUserIdUseCase.execute());
        if (undefined !== id) {
            setLocationId(id);
            fetchLocations([id]);
        }
    }, []);

    const ticketsToValidate = id === undefined ? [] : getToValidateTicketsByLocationId(id) ?? [];
    const ticketsWaiting = id === undefined ? [] : getWaitingTicketsByLocationId(id) ?? [];
    const ticketsAccepted = id === undefined ? [] : getTicketsAcceptedByLocationId(id) ?? [];
    const ticketsDeclined = id === undefined ? [] : getTicketsDeclinedByLocationId(id) ?? [];
    const location = id === undefined ? undefined : getLocationById(id);

    if (location === undefined) {
        return null;
    }

    return <PageTemplate>
        <div className={classes(width('100%'))}>
            <div className={classes(backgroundColor("secondary"), border(), round(), padding('xy', '10px'))}>
                <LabelNewLine className={classes(textSize('30px'))} label={LABEL_LOCATION_TICKETS_TITLE(<span key={`tickets-of-${location.name}`} className={`font-bold`}>&nbsp;{location.name}</span>)}/>
            </div>
            <div className={classes(margin('t', '10px'), flex("center", 'left', '10px'))}>
                <LabelNewLine label={CTA_TICKETS_STATUS_QUEUE} className={classes(backgroundColor("secondary", true, section === 'queue'), border(), round(), padding('xy', '10px'))} onClick={() => changeSection('queue')}/>
                <LabelNewLine label={CTA_TICKETS_STATUS_WAITING_LIST} className={classes(backgroundColor("secondary", true, section === 'waiting'), border(), round(), padding('xy', '10px'))} onClick={() => changeSection('waiting')}/>
                <LabelNewLine label={CTA_TICKETS_STATUS_ACCEPTED} className={classes(backgroundColor("secondary", true, section === 'accepted'), border(), round(), padding('xy', '10px'))} onClick={() => changeSection('accepted')}/>
                <LabelNewLine label={CTA_TICKETS_STATUS_DECLINED} className={classes(backgroundColor("secondary", true, section === 'declined'), border(), round(), padding('xy', '10px'))} onClick={() => changeSection('declined')}/>
            </div>
            {section === 'queue' && <div className={classes(margin('t', '10px'))}>
                {ticketsToValidate !== undefined && <>
                    {ticketsToValidate.length === 0 ?
                        <LabelNewLine className={classes(italic(), padding('xy', '10px'), textAlign("center"))} label={LABEL_NO_TICKETS_IN_QUEUE}/>
                        : <div className={`flex flex-wrap mt-[10px] gap-[5px] justify-center`}>
                            {ticketsToValidate.map((ticketId: string, index: number) => <TicketInQueueCard key={`ticket-${index}`} ticketId={ticketId} index={index} displayDirection={true}/>)}
                        </div>
                    }
                </>}
            </div>}
            {section === 'waiting' && <div className={classes(margin('t', '10px'))}>
                {ticketsWaiting !== undefined && <>
                    {ticketsWaiting.length === 0 ?
                        <LabelNewLine className={classes(italic(), padding('xy', '10px'), textAlign("center"))} label={LABEL_NO_TICKETS_IN_QUEUE}/>
                        : <div className={`flex flex-wrap mt-[10px] gap-[5px] justify-center`}>
                            {ticketsWaiting.map((ticketId: string, index: number) => <TicketWaitingCard key={`ticket-${index}`} ticketId={ticketId} index={index} displayDirection={true}/>)}
                        </div>
                    }
                </>}
            </div>}
            {section === 'accepted' && <div className={classes(margin('t', '10px'))}>
                {ticketsAccepted !== undefined && <>
                    {ticketsAccepted.length === 0 ?
                        <LabelNewLine className={classes(italic(), padding('xy', '10px'), textAlign("center"))} label={LABEL_NO_TICKETS_IN_QUEUE}/>
                        : <div className={`flex flex-wrap mt-[10px] gap-[5px] justify-center`}>
                            {ticketsAccepted.map((ticketId: string, index: number) => <TicketAcceptedCard key={`ticket-${index}`} ticketId={ticketId} index={index} displayDirection={true}/>)}
                        </div>
                    }
                </>}
            </div>}
            {section === 'declined' && <div className={classes(margin('t', '10px'))}>
                {ticketsDeclined !== undefined && <>
                    {ticketsDeclined.length === 0 ?
                        <LabelNewLine className={classes(italic(), padding('xy', '10px'), textAlign("center"))} label={LABEL_NO_TICKETS_IN_QUEUE}/>
                        : <div className={`flex flex-wrap mt-[10px] gap-[5px] justify-center`}>
                            {ticketsDeclined.map((ticketId: string, index: number) => <TicketDeclinedCard key={`ticket-${index}`} ticketId={ticketId} index={index} displayDirection={true}/>)}
                        </div>
                    }
                </>}
            </div>}
        </div>
    </PageTemplate>
}

export default Tickets;