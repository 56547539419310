import {State} from "./State";
import {
    backgroundColor,
    border,
    borderColor,
    classes,
    display, flex,
    height, margin,
    position,
    width, zIndex
} from "../../Libraries/Theme/Theme";
import LocaleButton from "../../Libraries/Components/Common/Button/LocaleButton";
import ThemeButton from "../../Libraries/Components/Common/Button/ThemeButton";
import MenuSeparator from "../../Libraries/Components/Common/Menu/MenuSeparator/MenuSeparator";
import MenuUnloggedWithoutRegister
    from "../../Libraries/Components/Common/Menu/MenuUnloggedWithoutRegister/MenuUnloggedWithoutRegister";
import MenuLogged from "../../Libraries/Components/Common/Menu/MenuLogged/MenuLogged";
import MenuLinkRestaurants from "../../Libraries/Components/Common/Menu/MenuLink/Links/MenuLinkRestaurants";
import MenuLinkHome from "../../Libraries/Components/Common/Menu/MenuLink/Links/MenuLinkHome";
import MenuLocation from "../../Libraries/Components/Common/Menu/MenuLocation/MenuLocation";
import Logo from "../../Libraries/Components/Common/Menu/Logo/Logo";
import {LABEL_SUB_LOGO_ADMIN} from "../../Libraries/Constants/Labels";
import {IsLoggedInUseCase} from "../../Libraries/UseCases/User/IsLoggedInUseCase";

function Menu() {
    const {isOpened, toggle} = State();
    const isLoggedInUseCase = IsLoggedInUseCase();

    return !isOpened ? null : <>
        <div className={classes(position('fixed'), position('relative', '', '', "md"), display('block'), display('none', 'md'), width('100%'), height('100vh'), backgroundColor('modal'), zIndex('500'))} onClick={toggle}></div>
        <div className={classes(border('r'), borderColor(), width('300px'), position('fixed', '0', '0'), height('100vh'), backgroundColor('secondary'), zIndex('550'))}>
            <Logo subLogo={LABEL_SUB_LOGO_ADMIN}/>
            <MenuSeparator/>
            <MenuUnloggedWithoutRegister/>
            <MenuLogged/>
            <MenuSeparator/>
            {isLoggedInUseCase.execute() && <>
                <MenuLocation/>
                <MenuSeparator/>
                <MenuLinkHome/>
                <MenuLinkRestaurants/>
                <MenuSeparator/>
            </>}
            <div className={classes(flex("center", "center", '10px'), margin('t', '10px'))}>
                <LocaleButton alwaysShow={true}/>
                <ThemeButton alwaysShow={true}/>
            </div>
        </div>
    </>;
}

export default Menu;