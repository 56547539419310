import React from 'react';
import './App.css';
import Router from "./Router";
import {LocaleContextHook, LocaleContextProvider} from "./Libraries/Contexts/LocaleContext";
import {TranslationContextHook, TranslationContextProvider} from "./Libraries/Contexts/TranslationContext";
import Translator from "./Libraries/Utils/Translator";
import {
  FastManageLocationContextHook,
  FastManageLocationContextProvider
} from "./Libraries/Contexts/FastManageLocationContext";
import {ThemeCache} from "./Libraries/Caches/System/ThemeCache";
import {syncTheme} from "./Libraries/Theme/Theme";
import useQuery from "./Libraries/Hooks/Query/UseQuery";

function App() {
  const [locale, dispatchLocale] = LocaleContextHook();
  const [translation, dispatchTranslation] = TranslationContextHook();
  const [location, dispatchLocation] = FastManageLocationContextHook();
  const {theme} = ThemeCache();

  syncTheme(theme);
  Translator.loadTranslation(dispatchTranslation);
  document.title = `Queube - Admin`;

  // @ts-ignore
  return <LocaleContextProvider value={{locale, dispatchLocale}}>
    {/*@ts-ignore*/}
    <TranslationContextProvider value={{translation, dispatchTranslation}}>
      {/*@ts-ignore*/}
      <FastManageLocationContextProvider value={{location, dispatchLocation}}>
        <Router/>
      </FastManageLocationContextProvider>
    </TranslationContextProvider>
  </LocaleContextProvider>;
}

export default App;
