import {LogInUseCase} from "../../../../UseCases/User/LogInUseCase";
import InputPassword from "../Common/Form/InputPassword/InputPassword";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import InputLoginOrEmail from "../Common/Form/Input/InputLoginOrEmail";
import Label from "../../../Common/Label/Label";
import {LABEL_ERROR, LABEL_LOG_IN} from "../../../../Constants/Labels";
import {
    backgroundColor,
    boldText,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textSize
} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";

function LoginForm() {
    const {form, handleChange} = LogInUseCase();
    const logInUseCase = LogInUseCase();

    return <div className={classes(border(),backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <LabelNewLine className={classes(textSize('20px'), boldText())} label={LABEL_LOG_IN}/>
        <form onSubmit={logInUseCase.execute} className={classes(margin('t', '10px'))}>
            <div>
                <InputLoginOrEmail className={classes(backgroundColor('primary'), border())} form={form} handleChange={handleChange}/>
            </div>
            <div>
                <InputPassword className={classes(margin('t', '10px'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange}/>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default LoginForm;