import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import CreateForm from "../../Libraries/Components/Domain/Location/Form/CreateForm";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {useEffect} from "react";
import {sendCreateLocationPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function CreateLocation() {
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendCreateLocationPageView(getUserIdUseCase.execute());
    }, []);

    return <PageTemplate centeredContent={true}>
        <div className={`flex justify-center w-full`}>
            <CreateForm/>
        </div>
    </PageTemplate>;
}

export default CreateLocation;