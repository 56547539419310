import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import ConfigurePasswordForm from "../../Libraries/Components/Domain/Owner/Form/ConfigurePasswordForm";
import {LABEL_CONFIGURE_PASSWORD} from "../../Libraries/Constants/Labels";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {useEffect} from "react";
import {sendAccountSettingsPageView, sendConfigurePasswordPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function ConfigurePassword() {
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendConfigurePasswordPageView(getUserIdUseCase.execute());
    }, []);

    return <PageTemplate centeredContent={true}>
        <ConfigurePasswordForm/>
    </PageTemplate>;
}

export default ConfigurePassword;