import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {GetUserDataUseCase} from "../../Libraries/UseCases/User/GetUserDataUseCase";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import {LABEL_WELCOME_1, LABEL_WELCOME_2} from "../../Libraries/Constants/Labels";
import {
    border,
    borderColor,
    classes,
    flex,
    height,
    margin,
    padding, textAlign,
    textSize
} from "../../Libraries/Theme/Theme";
import UseQuery from "../../Libraries/Hooks/Query/UseQuery";
import {LocaleContext, UPDATE_LOCALE} from "../../Libraries/Contexts/LocaleContext";
import {locales} from "../../Libraries/Constants/Constants";
import {useContext, useEffect} from "react";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendCreateLocationPageView, sendHomepagePageView} from "../../Libraries/GoogleAnalytics/PageViews";

function Homepage() {
    const getUserIdUseCase = GetUserIdUseCase();
    const getUserDataUseCase = GetUserDataUseCase();
    const userData = getUserDataUseCase.execute();
    const localeContext = useContext(LocaleContext);

    const lang = UseQuery().get('lang');
    if (lang !== null) {
        if (Object.keys(locales).includes(lang)) {
            localeContext.dispatchLocale(UPDATE_LOCALE, locales[lang].value);
        }
    }

    useEffect(() => {
        sendHomepagePageView(getUserIdUseCase.execute());
    }, []);

    return <PageTemplate centeredContent={true}>
        <div className={classes(flex("center", "center"), height("full"))}>
            <div className={classes(border('r', '1px'), borderColor("primary"), padding('r', '5px'), margin('r', '5px'))}>
                <LabelNewLine className={classes(textSize('20px'), textAlign("right"))} label={LABEL_WELCOME_1}/>
                <LabelNewLine className={classes(textSize('20px'), textAlign('right'))} label={LABEL_WELCOME_2}/>
            </div>
            <LabelNewLine className={classes(textSize('50px'))} label={userData === undefined ? null : userData.login}/>
        </div>
    </PageTemplate>;
}

export default Homepage;