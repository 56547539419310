import {Link} from "react-router-dom";
import {ReactComponent as CircledCross} from "../../Libraries/Icons/CircledCross.svg";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import Translator from "../../Libraries/Utils/Translator";
import Button from "../../Libraries/Components/Common/Button/Button";
import {SearchLocationsUseCase} from "../../Libraries/UseCases/Location/SearchLocationsUseCase";
import {AutoSearchLocationsUseCase} from "../../Libraries/UseCases/Location/AutoSearchLocationsUseCase";
import {SearchLocationsResetUseCase} from "../../Libraries/UseCases/Location/SearchLocationsResetUseCase";
import {LocationsSearchesLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/LocationsSearchesLoader";
import Card from "../../Libraries/Components/Domain/Location/Card/Card";
import {
    CTA_CREATE_LOCATION,
    LABEL_FILTER_ZONE,
    LABEL_LOCATION_SEARCH_BY_KEYWORD
} from "../../Libraries/Constants/Labels";
import Label from "../../Libraries/Components/Common/Label/Label";
import {
    backgroundColor,
    border,
    borderColor,
    classes,
    flex, margin,
    padding, round, textColor,
    textSize,
    width
} from "../../Libraries/Theme/Theme";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {useEffect} from "react";
import {sendCreateLocationPageView, sendLocationsPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function Locations() {
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendLocationsPageView(getUserIdUseCase.execute());
    }, []);

    AutoSearchLocationsUseCase();
    const searchLocationsUseCase = SearchLocationsUseCase();
    const searchLocationsResetUseCase = SearchLocationsResetUseCase();
    const {keywordDebounce, keyword} = SearchLocationsUseCase();
    const {getLocationsSearchByKeyword} = LocationsSearchesLoader();

    if (keyword === undefined) {
        return null;
    }

    const locationsSearch = getLocationsSearchByKeyword(keyword);

    return <PageTemplate>
        <div className={classes(width('100%'))}>
            <div className={classes(border(), borderColor("primary"), backgroundColor("secondary"), textColor(), padding('xy', '10px'), round())}>
                <Label label={LABEL_FILTER_ZONE} className={classes(textSize('20px'))}/>
                <div className={classes(flex("center", "between", '10px'))}>
                    <div>
                        <div className={classes(border(), borderColor('primary'), flex("center"), round(), backgroundColor("primary"), padding('xy', '5px'), margin('t', '10px'), border())}>
                            <div>
                                <input className={classes(padding('xy', '5px'), backgroundColor('primary'), textColor('primary'))} type={'text'} onChange={searchLocationsUseCase.execute} value={keywordDebounce ?? ''} placeholder={Translator.translate(LABEL_LOCATION_SEARCH_BY_KEYWORD)}/>
                            </div>
                            <div>
                                <CircledCross className={`cursor-pointer`} width={`20px`} onClick={searchLocationsResetUseCase.execute}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to={`/locations/create`}><Button className={classes(padding('xy', '10px', '', true))} label={CTA_CREATE_LOCATION}/></Link>
                    </div>
                </div>
            </div>
            {locationsSearch !== undefined && <div className={classes(flex("top", "center", '10px'), margin('t', '10px'))}>
                {locationsSearch.map((locationId: string, index: number) => <Card key={`location-${index}`} locationId={locationId}/>)}
            </div>}
        </div>
    </PageTemplate>
}

export default Locations;