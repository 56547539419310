import {useContext} from "react";
import Translator from "../Utils/Translator";
import {TranslationContext} from "../Contexts/TranslationContext";
import TranslationCache from "../Caches/System/TranslationCache";

function Translation({children}) {
  const translationContext = useContext(TranslationContext);

  if (Translator.translationsLoaded === false) {
    Translator.loadTranslation(translationContext);
  }

  if (null !== new TranslationCache().get()) {
    return children;
  }

  return <></>;
}

export {Translation};