import {useParams} from "react-router-dom";
import {useEffect} from "react";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {UpdateLocationUseCase} from "../../Libraries/UseCases/Location/UpdateLocationUseCase";
import {LocationsLoader} from "../../Libraries/Api/Loaders/IndexedLoaders/LocationsLoader";
import UpdateForm from "../../Libraries/Components/Domain/Location/Form/UpdateForm";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendLoginPageView, sendUpdateLocationPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function UpdateLocation() {
    const {id} = useParams();
    const {setLocationId} = UpdateLocationUseCase();
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendUpdateLocationPageView(getUserIdUseCase.execute());
        if (id !== undefined) {
            setLocationId(id);
            fetchLocations([id]);
        }
    }, []);

    if (id === undefined) {
        return null;
    }

    const location = getLocationById(id);
    if (location === undefined) {
        return null;
    }

    return <PageTemplate>
        <UpdateForm locationId={id}/>
    </PageTemplate>;
}

export default UpdateLocation;