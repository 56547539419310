import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Homepage from "./Pages/Homepage/Homepage";
import {Translation} from "./Libraries/RouterCondition/TranslationCondition";
import {AccountLogged, AccountUnlogged} from "./Libraries/RouterCondition/AccountCondition";
import Login from "./Pages/Login/Login";
import ConfigurePassword from "./Pages/ConfigurePassword/ConfigurePassword";
import CreateLocation from "./Pages/CreateLocation/CreateLocation";
import UpdateLocation from "./Pages/UpdateLocation/UpdateLocation";
import Locations from "./Pages/Locations/Locations";
import Tickets from "./Pages/Tickets/Tickets";
import {LogOutUseCase} from "./Libraries/UseCases/User/LogOutUseCase";
import {ApiManager} from "./Libraries/Utils/ApiManager";
import {UserAccountCache} from "./Libraries/Caches/User/UserAccountCache";
import UpdateLocationSchedule from "./Pages/UpdateLocationSchedule/UpdateLocationSchedule";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";

function Router() {
  const {saveUser} = UserAccountCache();
  const logOutUseCase = LogOutUseCase();
  ApiManager.logInFunction = saveUser;
  ApiManager.logOutFunction = logOutUseCase.execute;

  return <Translation>
    <AccountLogged>
      <BrowserRouter>
        <Routes>
          <Route path={`/`} element={<Homepage/>}/>
          <Route path="/locations" element={<Locations/>}/>
          <Route path="/locations/create" element={<CreateLocation/>}/>
          <Route path="/locations/:id" element={<UpdateLocation/>}/>
          <Route path="/locations/:id/tickets" element={<Tickets/>}/>
          <Route path="/locations/:id/schedule" element={<UpdateLocationSchedule/>}/>
          <Route path="/account/settings" element={<AccountSettings/>}/>
        </Routes>
      </BrowserRouter>
    </AccountLogged>
    <AccountUnlogged>
      <BrowserRouter>
        <Routes>
          <Route path={`/`} element={<Login/>}/>
          <Route path="/configure-password" element={<ConfigurePassword/>} />
        </Routes>
      </BrowserRouter>
    </AccountUnlogged>
  </Translation>;
}

export default Router;
