import Translator from "../../../../../../Utils/Translator";
import {LABEL_TEMPORARY_PASSWORD} from "../../../../../../Constants/Labels";
import InputPwd from "../../../../../Common/Form/InputPwd";

interface InputTemporaryPasswordInterface {
    form: any;
    handleChange: any;
    className?: string;
}

function InputTemporaryPassword({form, handleChange, className}: InputTemporaryPasswordInterface) {
    return <InputPwd
        inputData={form.fields.temporaryPassword}
        name={'temporaryPassword'}
        onChange={handleChange}
        hidden={true}
        placeholder={Translator.translate(LABEL_TEMPORARY_PASSWORD)}
        size={'full'}
        className={className ?? ''}
    />;
}

export default InputTemporaryPassword;