import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {UserAccountCache} from "../../Libraries/Caches/User/UserAccountCache";
import UpdateForm from "../../Libraries/Components/Domain/Owner/Form/UpdateForm";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {useEffect} from "react";
import {sendAccountSettingsPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function AccountSettings() {
    const accountCache = UserAccountCache();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendAccountSettingsPageView(getUserIdUseCase.execute());
    }, []);

    if (undefined === accountCache.user || undefined === accountCache.user.id) {
        return null;
    }

    return <PageTemplate>
        <UpdateForm ownerId={accountCache.user.id}/>
    </PageTemplate>;
}

export default AccountSettings;